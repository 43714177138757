import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const githubIcon = <FontAwesomeIcon icon={faGithub} />;
const linkedinIcon = <FontAwesomeIcon icon={faLinkedin} />;

const pageHeader = css`
  color: "#232129";
  font-family: -apple-system, "Roboto", sans-serif, serif;
  font-size: 24px;
  margin: 12px 8px;
`;

const portraitContainer = css`
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  border: 3px solid #fff;
`;

const portrait = css`
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  vertical-align: middle;
`;

const name = css`
  color: gray;
  align-self: center;
  font-size: xxx-large;
  text-transform: uppercase;
`;

const BREAKPOINTS = {
  xxs: "300px",
  xs: "420px",
  sm: "576px",
  md: "768px",
  lg: "900px",
  xl: "1200px",
  xxl: "1536px",
};

const container = css`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const content = css`
  font-size: 24px;
  font-weight: normal;
  text-align: justify;
  @media (min-width: ${BREAKPOINTS.xxs}) {
    margin: 12px 5%;
  }
  @media (min-width: ${BREAKPOINTS.xs}) {
    margin: 12px 10%;
  }
  @media (min-width: ${BREAKPOINTS.md}) {
    margin: 12px 15%;
  }
  @media (min-width: ${BREAKPOINTS.lg}) {
    margin: 12px 20%;
  }
  @media (min-width: ${BREAKPOINTS.xl}) {
    margin: 12px 30%;
  }
`;

const linkIcon = css`
  color: darkslategray;
  font-size: 48px;
  align-self: end;
  font-weight: normal;
  text-decoration: none;
  font-family: "Times New Roman", Times, serif;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

const IndexPage = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <main>
      <h3 css={pageHeader}>{frontmatter.title}</h3>
      <div css={container}>
        <h1 css={name}>John Nevin</h1>
        <div css={portraitContainer}>
          <StaticImage
            css={portrait}
            src="../images/canon-beach-crop.jpg"
            placeholder="blurred"
            layout="fixed"
            width={200}
            height={200}
            alt="Portrait of Me in front of Haystack Rock in Canon Beach, Oregon"
          />
        </div>
      </div>
      <span
        css={css`
          display: flex;
          flex-flow: row;
          justify-content: space-around;
          margin: 12px 20%;
        `}
      >
        <a
          css={linkIcon}
          href={frontmatter.github}
          target="_blank"
          rel="noreferrer"
        >
          {githubIcon}
        </a>
        <a
          css={linkIcon}
          href={frontmatter.link}
          target="_blank"
          rel="noreferrer"
        >
          {linkedinIcon}
        </a>
      </span>
      <div css={content} dangerouslySetInnerHTML={{ __html: html }} />
    </main>
  );
};

export default IndexPage;

export const About = graphql`
  query About {
    markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/about/*.md" }) {
      html
      frontmatter {
        slug
        title
        date
        link
        github
      }
    }
  }
`;
